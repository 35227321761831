<template>
  <div>
    <f-default-header :title="$t('pages.orders.title')" :subtitle="$t('pages.orders.history.subtitle')" />

    <f-card>{{ $t('labels.order') }}: {{ fullData.header.number }}</f-card>
    <f-card-spacer />

    <v-expansion-panels class="hhhmode">
      <v-expansion-panel v-for="(item, index) in fullData.data" :key="index">
        <v-expansion-panel-header>
          {{ dateFormat(item.created_at) }}
          <template v-slot:actions>
            <v-icon color="primary"> $expand </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <div class="sec-title">{{ $t('pages.orders.history.data') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              {{ $t('labels.type') }}: <strong>{{ item.order_type }}</strong
              ><br />
              <!-- Aux data ---<br /> -->
              <br />
              <hr />
              <br />
              {{ $t('labels.paymentCondition') }}: <strong>{{ paymentTranslation(item.payment).label }}</strong
              ><br />
              {{ $t('labels.biling') }}: <strong>{{ item.billing_type }}</strong
              ><br />
              {{ $t('labels.typeShipping') }}: <strong>{{ item.shipping_type }}</strong
              ><br />
              {{ $t('labels.shipCompany') }}: <strong>{{ item.shipping_company }}</strong
              ><br />
              <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="sec-title">{{ $t('pages.orders.history.optionalData') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              {{ $t('labels.finalCustomer') }}: <strong>{{ item.final_client }}</strong
              ><br />
              Voucher: <strong>{{ item.voucher_code }}</strong
              ><br />
              {{ $t('labels.observation') }}: <strong>{{ item.comment }}</strong
              ><br />
              <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="sec-title">{{ $t('labels.products') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="ttwrapper">
                <table class="ttview">
                  <tr>
                    <th>Part number</th>
                    <th>{{ $t('labels.models')}}</th>
                    <th>{{ $t('labels.description')}}</th>
                    <th>{{ $t('labels.unitValue')}}</th>
                    <th>{{ $t('labels.qtd')}}</th>
                    <th>{{ $t('labels.totalValue')}}</th>
                    <th>{{ $t('labels.dataProgram')}}</th>
                    <th>{{ $t('labels.segment')}}</th>
                    <th>{{ $t('labels.productLink')}}</th>
                  </tr>
                  <tr v-for="(it, id) in item.products" :key="id">
                    <td>{{ it.part_number }}</td>
                    <td>{{ it.model }}</td>
                    <td>{{ it.description }}</td>
                    <td>{{ moneyFormat(it.value) }}</td>
                    <td>{{ it.quantity }}</td>
                    <td>{{ moneyFormat(it.total_value) }}</td>
                    <td>{{ dateFormat(it.scheduled_date) }}</td>
                    <td>{{ it.segment }}</td>
                    <td>{{ it.link }}</td>
                  </tr>
                </table>
              </div>
              <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="sec-title">Total</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <strong>{{ moneyFormat(item.total) }}</strong>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <f-card-spacer />
    <f-actions-btn-holder>
      <v-btn color="secondary" elevation="0" large @click="back">
        voltar
      </v-btn>
    </f-actions-btn-holder>

    <f-card-spacer />
    <f-card-spacer />
  </div>
</template>

<script>
import dateFormater from "../../helpers/date_formater";
import numberFomater from "../../helpers/number_formater";
import { translatePaymentConditions } from "../../helpers/order_utils";

export default {
  data: () => ({
    id: null,
    fullData: null,
  }),
  created() {
    this.language = localStorage.getItem('currentLang');

    this.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    dateFormat(value) {
      if (value) {
        return dateFormater.fromDB(value, false);
      }
      return null;
    },
    moneyFormat(value) {
      if (value) {
        return numberFomater.money(value);
      }
      return null;
    },
    async getData() {
      this.fullData = await this.$store.dispatch("order/get", this.id);
    },
    paymentTranslation(value) {
      return translatePaymentConditions(value, this.language, "0");
    },
    back() {
      this.$router.push({
        name: "order-view",
        params: {
          id: this.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.ttview {
  th {
    text-align: left;
  }
  td {
    min-width: 150px;
  }
}
.ttwrapper {
  width: 100%;
  max-height: 400px;
  overflow: auto auto;
}
.hhhmode {
  .v-expansion-panel::before {
    box-shadow: none;
  }
}
.v-expansion-panel-header--active {
  font-weight: 700;
  font-size: 20px !important;
}
.sec-title {
  height: 35px;
  background-color: #f5f5f5;
  margin: 0 0 12px;
  padding: 8px 16px;
}
</style>
